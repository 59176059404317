<template>
    <div>
        <van-uploader
            v-model="arr"
            :max-size="max_size"
            :upload-text="upload_text"
            :max-count="max_count"
            :disabled="disabled"
            :deletable="deletable"
            :show-upload="show_upload"
            :result-type="result_type"
            :after-read="after_read"
            :url="url"
            accept="image/*"
            @oversize="oversize"
            @click-preview="DownFile"
        />
    </div>
</template>

<script>
import { uoloader_request } from '@/network/uploader.js'

export default {
    name:'',
    data(){
        return {
            arr: []
        }
    },
    computed:{},
    methods:{
        after_read(file) {
            uoloader_request(file['file'],this.url)
                .then((s) => {
                    if (s.status === 0) {
                        delete this.arr[this.arr.length - 1]
                        this.arr.push({
                            name: file['file']['name'],
                            url: this.$store.state.baseURL + s.result.url
                        })
                        this.$toast.success('上传成功!')
                        this.$emit('update_img',this.arr.filter(v=>v !== undefined))
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        oversize() {
            this.$toast.fail('文件大小不能超过' + (this.max_size/1048576) + 'M')
        },
        DownFile(file) {
            const arr = file.name.split('.')
            const type = arr[arr.length-1]
            if((type !=='png') && (type !=='jpg') && (type !=='jpeg')) {
                window.open(file.url, '_blank')
            }
        }
    },
    filters:{},
    props:{
        farr: {
            type: Array,
            default() {
                return []
            }
        },
        // accept: {
        //     type: String,
        //     default() {
        //         const arr = [
        //             'image/jpeg',   //JPG图片
        //             'image/png',    //PNG图片
        //             'application/msword',   //doc格式word
        //             'application/vnd.openxmlformats-officedocument.wordprocessingml.document',   //docx格式word
        //             'application/vnd.ms-excel',     //xls格式表格
        //             'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',    //xlsx格式表格
        //             'application/vnd.ms-powerpoint',    //ppt格式幻灯片
        //             'application/vnd.openxmlformats-officedocument.presentationml.presentation',    //pptx格式幻灯片
        //             'text/plain',   //txt文本
        //             'application/pdf'   //pdf
        //         ]

        //         return arr.join('|')
        //     }
        // },
        disabled: {
            type: Boolean,
            default() {
                return false
            }
        },
        deletable: {
            type: Boolean,
            default() {
                return true
            }
        },
        show_upload: {
            type: Boolean,
            default() {
                return true
            }
        },
        max_size: {
            type: Number,
            default() {
                return 10*1024*1024
            }
        },
        max_count: {
            type: Number,
            default() {
                return 10
            }
        },
        upload_text: {
            type: String,
            default() {
                return '点击上传'
            }
        },
        result_type: {
            type: String,
            default() {
                return 'file'
            }
        },
        url: {
            type: String,
            default() {
                return ''
            }
        }
    },
    created(){
        this.arr = this.farr
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{
        farr(newVal) {
            this.arr = newVal
        }
    }
}
</script>

<style>
</style>